<template>
  <div class="blind">
    <section class="section">
      <div class="left">
        <h2>
          盲人医疗按摩人员继续教育
        </h2>
        <ul class="box">
          <li v-if="eduList.country">
            <div class="top">
              <div class="type">
                <div class="level">
                  {{eduList.country.batchtime}}期·国级
                </div>
                <div class="status gray" v-if="eduList.country.during_status == 1">
                  未开始
                </div>
                <div class="status" v-if="eduList.country.during_status == 2">
                  报名中
                </div>
                <div class="status gray" v-if="eduList.country.during_status == 3">
                  已结束
                </div>
                <div class="status green" v-if="eduList.country.during_status == 4">
                  已报名
                </div>
              </div>
              <h3>国家级盲人医疗按摩人员继续教育培训</h3>
            </div>
            <div class="center">
              <div class="time">
                <div class="name">时间：</div>
                {{ eduList.country.trainStart }}至{{ eduList.country.trainEnd }}
              </div>
              <div class="time">
                <div class="name">周期：</div>
                {{ eduList.country.during_month }}天
              </div>
              <div class="address">
                <div class="name">地点：</div>{{eduList.country.trainAddress}}
              </div>
              <div class="apply">
                <div class="name">报名时间：</div>
                {{ eduList.country.applyStart }}至{{ eduList.country.applyEnd }}
              </div>
            </div>
            <div class="bottom">
              <a :href="eduList.country.notice" target="_blank" class="more" v-if="eduList.country.notice">
                查看通知详情 <img src="~/static/lanzhou/blind/right.png" alt="">
              </a>
              <el-button type="primary" class="btn" :disabled="eduList.country.during_status == '2'?false:true" @click="getApply(eduList.country.id,eduList.country.during_status)">立即报名</el-button>
            </div>
          </li>
          <li v-if="eduList.province">
            <div class="top">
              <div class="type">
                <div class="level green">
                  {{eduList.province.batchtime}}期·省级
                </div>
                <div class="status gray" v-if="eduList.province.during_status == 1">
                  未开始
                </div>
                <div class="status" v-if="eduList.province.during_status == 2">
                  报名中
                </div>
                <div class="status gray" v-if="eduList.province.during_status == 3">
                  已结束
                </div>
                <div class="status green" v-if="eduList.province.during_status == 4">
                  已报名
                </div>
              </div>
              <h3>省级级盲人医疗按摩人员继续教育培训</h3>
            </div>
            <div class="center">
              <div class="time">
                <div class="name">时间：</div>
                {{ eduList.province.trainStart }}至{{ eduList.province.trainEnd }}
              </div>
              <div class="time">
                <div class="name">周期：</div>
                {{ eduList.province.during_month }}天
              </div>
              <div class="address">
                <div class="name">地点：</div>{{eduList.province.trainAddress}}
              </div>
              <div class="apply">
                <div class="name">报名时间：</div>
                {{ eduList.province.applyStart }}至{{ eduList.province.applyEnd }}
              </div>
            </div>
            <div class="bottom">
              <a :href="eduList.province.notice" target="_blank" class="more" v-if="eduList.province.notice">
                查看通知详情 <img src="~/static/lanzhou/blind/right.png" alt="">
              </a>
              <el-button type="primary" class="btn" :disabled="eduList.province.during_status == '2'?false:true" @click="getApply(eduList.province.id,eduList.province.during_status)">立即报名</el-button>
            </div>
          </li>
        </ul>
      </div>
      <div class="right">
        <h2>
          最新盲人按摩培训
        </h2>
        <ul class="box">
          <li v-for="item in train" :key="item.id" @click="goRouter('/training/trainDetail',item.id)">
            <div class="banner">
              <div class="source">
                机构项目培训
              </div>
              <img :src="item.picurl" :alt="item.trainName" class="err_img">
            </div>
            <div class="info">
              <h3>
                {{item.trainName}}
              </h3>
              <div class="source">
                兰州
              </div>
              <div class="status" v-if="item.ing == 2">
                报名中
              </div>
              <div class="status green" v-if="item.ing == 3">
                报名截止
              </div>
              <div class="status gray" v-if="item.ing == 4">
                已报名
              </div>
              <p>
                <img src="~/static/lanzhou/icon/rili.png" alt="">
                {{ item.appplyBeginTime }}—{{ item.trainBeginTime }}
              </p>
              <p>
                <img src="~/static/lanzhou/icon/didian.png" alt="">
                {{ item.trainC }} {{ item.trainT }}{{ item.trainAddress }}
              </p>
              <p>
                <img src="~/static/lanzhou/icon/phone.png" alt="">
                {{ item.telPhone || '暂无联系方式' }}
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <section class="section1">
      <h2>兰州市盲人按摩店</h2>
      <ul class="blind_list flex-wrap">
        <li class="flex" v-for="(item,index) in blind" :key="index" @click="$linkRouter('/contentDetail/detail',{id:item.id,name:'盲按'})">
          <img :src="item.logo" alt="" />
          <div class="contents marL15">
            <h3 class="line_clamp1">{{item.name}}</h3>
            <p class="p1 line_clamp1">地址：{{item.addrProvince+item.addrCity+item.addrThreeCity+item.addrDetail}}</p>
            <p class="p1">人员：{{item.nums}}</p>
            <p class="p1">床位：{{ item.beds }}</p>
          </div>
        </li>
      </ul>
    </section>
    <div class="pages">
      <el-pagination
          background
          class="work_pagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.pageNum"
          :page-size="page.pageSize"
          layout="prev, pager, next, total, jumper"
          :total="page.total"
      ></el-pagination>
    </div>
    <login-prompt
        :goShow="isHide"
        @close="isHide = false"
        title="盲按机构"
    ></login-prompt>
  </div>
</template>

<script>
import loginPrompt from "@/components/public/loginPrompt";
export default {
  components:{
    loginPrompt
  },
  data() {
    return {
      blind:[],
      page:{
        pageNum:1,
        pageSize:10,
        total:0,
        addrProvince:'p24',
      },
      eduList:[],
      train:[],
      isLogin:'',
      isHide:false,
    }
  },


  methods: {
    async info(){
      //兰州市盲人按摩店
      let res = await this.$axios.post('/api/app-jycy-henan/henan/bindshop/list',this.page);
      if (res.data.success){
        this.blind = res.data.data.records;
        this.page.total = res.data.data.total;
      }
      // 盲人医疗按摩人员继续教育
      let res1 = await this.$api.lanzhouHeadListApi()
      if(res1.data.success){
          this.eduList = res1.data.data;
          console.log(res1.data.data)
      }
      //最新盲人按摩培训
      let res2 = await this.$api.trainListApi({
        pageNum:1,
        pageSize:2,
        trainType:'08'
      })
      if(res2.data.success){
        this.train = res2.data.data.records;
      }
    },
    getApply(id,status){
      this.isLogin = this.$store.state.userType || JSON.parse(localStorage.getItem('userInfo'));
      if(this.isLogin){
        this.goRouter('./blindEdit',id);
      }else{
        this.isHide = true;
      }
    },
    goRouter(url, id) {
      const {href} = this.$router.resolve({
        path: url,
        query: {
          id: id,
        },
      });
      window.open(href);
    },
    //分页
    handleCurrentChange: function (val) {
      this.page.pageNum = val;
      this.info();
      window.scrollTo(0, 400);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
  },
  created() {
      this.info();
  }
}
</script>
<style scoped lang="less">
@import "~assets/lanzhou/blind.less";
</style>
